import React, { useEffect } from "react";
import PodoApi from "../../api/PodoApi";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from "moment";
import DebugLog from "../DebugLog";
import useFetch from "../../hooks/useFetch";
import Loading from "../Loading/Loading";
import { ContractExecutionPlace, ContractKind, ContractState } from '../../utils/contractEnums'
import AlertErr from "../Alert/AlertErr";
import FilterSwitch from "./FilterSwitch";
import { IFilter, IFilterCurrent } from '../../types/IFilter';


interface iFilterDetail {
  id: string,
  version?: boolean
}

const FilterDetail = ({ id, version }: iFilterDetail) => {

  const { loading, err, data, fetch } = useFetch(
    () => version ? PodoApi.getFilterVersionDetail(id) : PodoApi.getFilterDetail(id).then((filter: IFilter) => filter.currentVersion)
  )
  const filterCur = data as IFilterCurrent
  const filterParams = filterCur?.params

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, version])

  const addRow = (label: string, value?: string | number | null) => ({ label, value })

  const rows = filterParams ? [
    // addRow("Název", filter.name),
    addRow("Vytvořeno", filterCur.createdAt ? moment(filterCur.createdAt).format("DD.MM.YYYY HH:mm:ss") : ""),
    addRow("Platnost filtru do", filterCur.replacedAt ? moment(filterCur.replacedAt).format("DD.MM.YYYY HH:mm:ss") : "Aktuální"),
    addRow("Notifikace e-mailem", filterCur.notifyActive ? "Ano" : "Ne"),//  (true -> Ano / false -> Ne)
    addRow("Klíčová slova / fráze", filterParams.phrase),
    addRow("Negativni klíčová slova fráze", filterParams.notPhrase),
    addRow("Tagy", filterParams.tags?.map(a => `#${a}`).join(', ')),
    addRow("Způsob vyhledávání", filterParams.alternativeSearch ? "Obsahuje aspoň jedno ze slov" : "Obsahuje uvedené slovní spojení"),
    addRow("CPV kódy", filterParams.cpvCodes?.join(", ")),
    addRow("Zahrnout i podřízené CPV kódy", filterParams.includeSubCpvCodes ? "Ano" : "Ne"),
    addRow("Zahrnout i zakázky bez CPV", filterParams.includeWithoutCpvCodes ? "Ano" : "Ne"), // (true či 1 -> Ano / false či 0 -> Ne)
    addRow("Místo plnění", filterParams.executionPlaces?.map((place: string) => ContractExecutionPlace[place as keyof typeof ContractExecutionPlace]).join(", ")),
    addRow("Zahrnout i zakázky bez místa plnění", filterParams.includeWithoutExecutionPlaces ? "Ano" : "Ne"),// (true či 1 -> Ano / false či 0 -> Ne)
    addRow("Stav zakázky", filterParams.statuses?.map((item: string) => ContractState[item as keyof typeof ContractState]).join(", ")),
    addRow("Druh zakázky", filterParams.kinds?.map((item: string) => ContractKind[item as keyof typeof ContractKind]).join(", ")),
    addRow("Datum zahájení od", filterParams.beginsAtFrom),
    addRow("Datum zahájení do", filterParams.beginsAtTo),
    addRow("Datum publikování od", filterParams.publishedAtFrom),
    addRow("Datum publikování do", filterParams.publishedAtTo),
    addRow("Lhůta pro podání nabídek od", filterParams.offersDeadlineAtFrom),
    addRow("Lhůta pro podání nabídek do", filterParams.offersDeadlineAtTo),
    addRow("Název zadavatele", filterParams.submitterName),
    addRow("IČO zadavatele", filterParams.submitterIdentificationNumber),
    addRow("Číslo zakázky na Profilu", filterParams.profileNumber),
    addRow("Cena od", filterParams.priceMin),
    addRow("Cena do", filterParams.priceMax),
    addRow("Zahrnout zakázky bez uvedené hodnoty", filterParams.includeWithoutPrice ? "Ano" : "Ne")
  ] : []

  const mainId = version ? filterCur?.filter?.slice(filterCur.filter?.lastIndexOf('/')+1) || '' : id

  return (
    <>
      <h3>Nastavení filtru: {filterCur?.name}</h3>

      {loading && <Loading/>}
      {err && <AlertErr>{err}</AlertErr>}

      {(!(loading || err) && filterParams) &&
      <>
        <FilterSwitch id={mainId} curId={filterCur.id}/>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" style={{ minWidth: 240 }}>
                    {row.label}
                  </TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <DebugLog val={data}/>
      </>
      }
    </>
  )
}

export default FilterDetail
